<template>
  <div>
    <WBCheckin />
    <WBSession />
    <WBNotificationCenter />
    <WBDrawer>
      <main class="px-0 md:px-0 h-full"><slot /></main>
    </WBDrawer>
  </div>
</template>

<script setup lang="ts">
  useHead({
    link: [
      {
        rel: "stylesheet",
        href: useTenantAssetThemeUrl(useRoute().params.tenant as string)
      }
    ]
  });
</script>
