<template>
  <div v-if="activate" class="toast-end toast z-10 lg:max-w-lg">
    <div
      v-for="n in notifications"
      :id="n.id"
      class="alert shadow-md"
      :class="{
        'alert-info': n.type == NotificationType.info,
        'alert-success': n.type == NotificationType.success,
        'alert-warning': n.type == NotificationType.warning,
        'alert-error': n.type == NotificationType.error
      }"
    >
      <div class="relative w-full pr-5">
        <Icon name="mdi:fire" size="30"></Icon>
        <span class="py-[10px]">{{ n.message }} </span>
        <button
          @click="discard(n.id!)"
          class="absolute -right-2 -top-3 flex h-6 w-6 cursor-pointer items-center justify-center px-2 text-lg"
        >
          x
        </button>
        <span class="absolute -right-2 -bottom-[10px] px-2 text-xs opacity-50">
          {{ useTimeFormat(n.ts) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { NotificationType } from "@/plugins/1.eventBus";
  import type { NotificationEvent } from "@/plugins/1.eventBus";

  const { $listen } = useNuxtApp();
  const notifications = ref<Array<NotificationEvent>>([]);
  const activate = ref(false);

  $listen("notification:show", (notifs) => {
    activate.value = true;
    for (let n of notifs) {
      n.id = `n${Date.now()}${Math.floor(Math.random() * 100)}`;
      n.ts = new Date();
      notifications.value.push(n);
    }
  });

  const discard = (id: string) => {
    document.getElementById(id)?.remove();
  };
</script>
