<template>
  <div
    v-if="preparing"
    class="fixed top-0 left-0 z-50 flex h-screen w-screen flex-row items-center justify-center p-12 backdrop-blur-sm"
  >
    <div
      class="flex flex-row items-center gap-x-2 rounded-md bg-primary p-4 shadow"
    >
      <div class="text-primary-content">
        Please wait while we set up your session
      </div>
      <div role="status">
        <Icon name="mdi:circle-outline" size="32" />
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const preparing = ref(true);
  (async () => {
    preparing.value = (await useTenantCheckin()) === 499;
  })();
</script>
