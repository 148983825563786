<template></template>

<script setup lang="ts">
  import { useSubscriptionStore } from "@/stores/subscription";
  import { useSessionStore } from "@/stores/session";

  const subscriptionStore = useSubscriptionStore();
  subscriptionStore.initSubscription();

  const sessionStore = useSessionStore();
  sessionStore.initSession().then(() => {
    useHead({
      titleTemplate: (titleChunk) => {
        const titleBase = `${sessionStore.tenant?.name} Policy Center`;
        return titleChunk ? `${titleChunk} @ ${titleBase}` : titleBase;
      }
    });
  });
</script>
